import { languageSelector as ls } from "@covvi/language-selector"

import BasicModal from "@ui/modals/BasicModal"
import CVCloseModal from "@ui/modals/CVCloseModal"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"
import { ButtonRow } from "@ui/sections/ButtonRow"

interface Props {
  showingConfirmationModal: boolean
  setShowingConfirmationModal: Function
  updateConfig: Function
  saving: boolean
}

const SaveConfigConfirmation = ({
  showingConfirmationModal,
  setShowingConfirmationModal,
  updateConfig,
  saving,
}: Props) => {
  return (
    <BasicModal
      open={showingConfirmationModal}
      setClosed={() => setShowingConfirmationModal(false)}
    >
      <CVCardHeader>
        <CVCardTitle title={ls.getText("Are You Sure?")} />
        <CVCloseModal setShowingModal={setShowingConfirmationModal} />
      </CVCardHeader>
      <CVCardBody>
        <div className="p-[30px] flex flex-col flex-1 space-y-2">
          <p>{ls.getText("warn_config_overwrite")}</p>
          <p>{ls.getText("confirm_config_overwrite")}</p>
        </div>
      </CVCardBody>
      <CVCardFooter>
        <ButtonRow
          buttonArray={[
            {
              title: ls.getText("Save Config"),
              variant: "contained",
              loading: saving,
              onClick: () => updateConfig(),
            },
            {
              variant: "outlined",
              title: ls.getText("Cancel"),
              onClick: () => setShowingConfirmationModal(false),
            },
          ]}
        />
      </CVCardFooter>
    </BasicModal>
  )
}

export default SaveConfigConfirmation
