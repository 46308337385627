import { useEffect, useState } from "react"

import { languageSelector as ls } from "@covvi/language-selector"
import { Config } from "@util/parser/Parser"
import { getHandConfigs, uploadConfigHistory } from "@util/firebase/handFunctions"
import { useAuth } from "@context/AuthContext"
import { useCurrentHandContext, useCurrentSessionContext } from "@context/remoteAssistProviders"
import capitaliseString from "@util/commonFunctions/capitaliseString"

import CVSubtitle from "@ui/text/CVSubtitle"
import Spinner from "@ui/spinners/Spinner"
import LabelButtonInfo from "@ui/inputs/ConfigInputs/LabelButtonInfo"
import { AlertSection } from "@ui/alerts/AlertSection"
import { replaceAt } from "@util/commonFunctions/replaceAt"

const ConfigActions = ({ config }: { config: Config }) => {
  const { profile } = useAuth()
  const { initialConfig, currentConfig, emitData, commandResponse, clearCurrentCommandResponse } =
    useCurrentHandContext()
  const { client, setIsInConfigActions } = useCurrentSessionContext()
  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true)
  const [factoryHex, setFactoryHex] = useState<string>()
  const thumbSwitchingConfig = require("@assets/configs/Bebionic.json")
  const [disableButtons, setDisableButtons] = useState<boolean>(false)
  const [hasSentResetCommand, setHasSentResetCommand] = useState<boolean>(false)
  const [resetCommandSuccess, setResetCommandSuccess] = useState<boolean>(false)
  const [currentIsFactory, setCurrentIsFactory] = useState<boolean>(false)
  const [currentIsThumbSwitching, setCurrentIsThumbSwitching] = useState<boolean>(false)
  const [currentIsCoapt, setCurrentIsCoapt] = useState<boolean>(false)
  const [configHasBeenArchived, setConfigHasBeenArchived] = useState<boolean>(false)

  const clearGripsAndTrigs = (configHex: string) => {
    if (config.params.config_version.value === "006B") {
      return (
        configHex.substring(0, 66) +
        "03" +
        configHex.substring(68, 256) +
        "00".repeat(15) +
        configHex.substring(286, 416) +
        "010100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"
      )
    } else {
      return (
        configHex.substring(0, 256) +
        "00".repeat(15) +
        configHex.substring(286, 416) +
        "010100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"
      )
    }
  }

  const updatedThumbSwitchingHex = () => {
    if (!initialConfig) {
      return ""
    } else {
      const updatedTriggers = replaceAt(
        initialConfig?.configHex,
        248,
        thumbSwitchingConfig.config.substring(248, 286)
      )
      const updatedGrips = replaceAt(
        updatedTriggers,
        416,
        thumbSwitchingConfig.config.substring(416, 480)
      )
      return updatedGrips
    }
  }

  const configMatchesFactory = () => {
    const optTrig = (hex: string) => hex.substring(64, 286)
    const grips = (hex: string) => hex.substring(416, 480)
    if (
      currentConfig &&
      factoryHex &&
      optTrig(currentConfig.configHex) === optTrig(factoryHex) &&
      grips(currentConfig.configHex) === grips(factoryHex)
    ) {
      setCurrentIsFactory(true)
    } else {
      setCurrentIsFactory(false)
    }
  }

  useEffect(() => {
    setResetCommandSuccess(false)
    configMatchesFactory()
    setCurrentIsThumbSwitching(currentConfig?.configHex === updatedThumbSwitchingHex())
    currentConfig &&
      setCurrentIsCoapt(currentConfig?.configHex === clearGripsAndTrigs(currentConfig.configHex))
  }, [currentConfig, factoryHex])

  useEffect(() => {
    setIsInConfigActions(true)
    return () => setIsInConfigActions(false)
  }, [])

  useEffect(() => {
    disableButtons && setDisableButtons(false)
  }, [currentConfig])

  useEffect(() => {
    initialConfig &&
      initialConfig.serialNumber &&
      profile &&
      getHandConfigs(initialConfig.serialNumber, profile)
        .then((res) => {
          let factory =
            res.filter((config) => config.name === "Factory").length !== 0
              ? res.filter((config) => config.name === "Factory")[0]
              : undefined

          if (factory) {
            setFactoryHex(factory.configHex)
          }
          setPageIsLoading(false)
        })
        .catch((e) => {
          setPageIsLoading(false)
        })
  }, [initialConfig, profile])

  const archiveConfig = () => {
    setDisableButtons(true)
    let isInitialConfig = currentConfig?.configHex === initialConfig?.configHex

    let hexToSave = currentConfig && currentConfig.configHex
    console.log(hexToSave, profile!.role, currentConfig)
    if (hexToSave && profile!.role && currentConfig) {
      uploadConfigHistory(
        {
          configHex: hexToSave,
          configVersion: currentConfig.configVersion?.toString() || ls.getText("Unknown"),
          firmwareVersion: currentConfig.firmwareVersion || ls.getText("Unknown"),
          serialNumber: currentConfig.serialNumber || ls.getText("Unknown"),
          name: `ARCHIVED_${Date.now().toString()}_RAS`,
          setBy: profile!.uid,
          userGrips: currentConfig.userGrips && currentConfig.userGrips,
          date: new Date(),
          userAppVersion: isInitialConfig
            ? initialConfig?.userAppVersion || `COVVI Portal ${process.env.REACT_APP_VERSION}`
            : `COVVI Portal ${process.env.REACT_APP_VERSION}`,
        },
        profile!
      )
        .then((res) => {
          setDisableButtons(false)
          setConfigHasBeenArchived(true)
        })
        .catch((e) => {
          console.error(e, "Error Archiving Config")
          setDisableButtons(false)
        })
    } else {
      profile &&
        ["Admin", "Tech Team Member"].includes(profile.role) &&
        console.error("Error Archiving Config")
      setDisableButtons(false)
    }
  }

  useEffect(() => {
    if (commandResponse === "41" && hasSentResetCommand) {
      setResetCommandSuccess(true)
      clearCurrentCommandResponse()
      setDisableButtons(false)
      setHasSentResetCommand(false)
      setCurrentIsFactory(false)
      setCurrentIsThumbSwitching(false)
      setCurrentIsCoapt(false)
    }
  }, [commandResponse, hasSentResetCommand])

  return (
    <>
      {pageIsLoading ? (
        <Spinner />
      ) : (
        <>
          <CVSubtitle text={ls.getText("Default Configs")} />
          {factoryHex && (
            <LabelButtonInfo
              labelText={ls.getText("Factory")}
              buttonText={ls.getText("Apply")}
              helperText={ls.getText("factory_config_description")}
              disabled={!client || disableButtons}
              onClick={() => {
                setDisableButtons(true)
                emitData({ type: "config", data: factoryHex })
              }}
              isLoading={disableButtons && !currentIsFactory}
              isDisabledBecauseSuccess={currentIsFactory}
            />
          )}
          <LabelButtonInfo
            labelText={ls.getText("Thumb Switching")}
            buttonText={ls.getText("Apply")}
            helperText={ls.getText("thumb_switching_config_description")}
            disabled={!client || disableButtons}
            onClick={() => {
              setDisableButtons(true)
              emitData({ type: "config", data: updatedThumbSwitchingHex() })
            }}
            isLoading={disableButtons && !currentIsThumbSwitching}
            isDisabledBecauseSuccess={currentIsThumbSwitching}
          />
          <LabelButtonInfo
            labelText={ls.getText("COAPT Pattern Recognition")}
            buttonText={ls.getText("Apply")}
            helperText={ls.getText("coapt_default_description")}
            disabled={!client || disableButtons}
            onClick={() => {
              setDisableButtons(true)
              emitData({ type: "config", data: clearGripsAndTrigs(initialConfig!.configHex) })
            }}
            isLoading={disableButtons && !currentIsCoapt}
            isDisabledBecauseSuccess={currentIsCoapt}
          />
          <LabelButtonInfo
            labelText={ls.getText("COVVI Default")}
            buttonText={ls.getText("Apply")}
            helperText={ls.getText("covvi_default_description")}
            disabled={!client || disableButtons}
            onClick={() => {
              setDisableButtons(true)
              setHasSentResetCommand(true)
              emitData({ type: "bleCommand", data: "ZF0A007F525354204652414D" })
            }}
            isLoading={disableButtons}
            isDisabledBecauseSuccess={resetCommandSuccess}
          />
          <div className="mt-2">
            <AlertSection
              title={capitaliseString(ls.getText("warning"))}
              content={ls.getText("ra_hand_restart_disconnect_warning")}
              severity="warning"
            />
          </div>
          {profile && ["Admin", "Tech Team Member"].includes(profile?.role) && (
            <>
              <div className="border-t border-[#EAEAEA] my-[30px] w-full" />
              <CVSubtitle text={ls.getText("Archive")} />
              <LabelButtonInfo
                labelText={ls.getText("Archive Copy Of Config")}
                buttonText={ls.getText("Archive")}
                helperText={ls.getText("archive_config_helptext")}
                disabled={
                  !client || configHasBeenArchived || (disableButtons && !configHasBeenArchived)
                }
                isDisabledBecauseSuccess={!!configHasBeenArchived}
                onClick={() => {
                  archiveConfig()
                }}
                isLoading={disableButtons && !configHasBeenArchived}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default ConfigActions
