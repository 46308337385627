import { getCovviUids, getMyUsers, setCovviUids } from "./userFunctions"
import { firestore, storage, functions } from "./firebase"
import {
  ref,
  listAll,
  uploadBytes,
  deleteObject,
  getBlob,
  StorageReference,
} from "firebase/storage"
import {
  Config,
  GeneralErrorsObj,
  ProfileData,
  ErrorCodeData,
  ErrorAndTime,
  HandFirestoreData,
} from "@typesFolder/types"
import {
  DataCounter,
  DataGrips,
  DataLimits,
  DataTriggers,
  DataUsage,
  ShortSerialStats,
  SingleHandStats,
  StatisticsWithConfigInfo,
  StatsPoss,
} from "@typesFolder/statsTypes"
import {
  query,
  collection,
  getDocs,
  where,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
  setDoc,
  deleteDoc,
  documentId,
} from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import { languageSelector as ls } from "@covvi/language-selector"
import { getStatisticsSchema } from "@covvi/common-functions"
import { FireStoreHand } from "@typesFolder/handTypes"

const getLatestConfig = (hand: FireStoreHand): Promise<FireStoreHand> =>
  getDoc(doc(firestore, `Hands/${hand.serialNumber}/Configs/Live Config`))
    .then((res) => {
      const liveConfig = res.data()
      if (!liveConfig) {
        return hand
      } else {
        return {
          ...hand,
          latest_config: liveConfig.date.seconds,
          latestConfigString: liveConfig.configHex,
        }
      }
    })
    .catch(() => hand)

export const getListOfHands = (profile: ProfileData) => {
  return new Promise<FireStoreHand[]>(async (resolve, reject) => {
    if (["Customer Service Team Member", "Tech Team Member", "Admin"].includes(profile.role)) {
      let hands: FireStoreHand[] = []
      await getDocs(query(collection(firestore, "Hands")))
        .then((snap) => {
          snap.forEach((doc) => {
            hands.push({ serialNumber: doc.id, ...doc.data() } as FireStoreHand)
          })
        })
        .catch((e) => console.log(e))

      Promise.all(hands.map((hand) => getLatestConfig(hand)))
        .then(resolve)
        .catch((e) => reject(e))
    } else {
      reject("insufficient privilege")
    }
  })
}

export const getMyHands = (profile: ProfileData) => {
  return new Promise<FireStoreHand[]>(async (resolve, reject) => {
    let associatedHandsStrings: string[] = profile.associated_hands
      ? profile.associated_hands.map((serial) => serial.slice(-6))
      : []
    let associatedHands: FireStoreHand[] = []

    if (profile?.associated_users) {
      await getMyUsers(profile.uid, profile.role).then((users) => {
        users.forEach((user) => {
          if (user.associated_hands) {
            associatedHandsStrings = [
              ...associatedHandsStrings,
              ...user.associated_hands.map((hand) => hand.slice(-6)),
            ]
          }
        })
      })
    }
    const associatedHandsLastSix = [
      ...new Set(
        await Promise.all(
          [...new Set(associatedHandsStrings)].map((serial) => getLastSixFromSerial(serial))
        )
      ),
    ]

    let chunkArray: string[][] = []
    chunkArray = [...Array(Math.ceil(associatedHandsLastSix.length / 30))].map((_) =>
      associatedHandsLastSix.splice(0, 30)
    )

    const getHandsByChunk = (chunk: string[]) =>
      getDocs(query(collection(firestore, "Hands"), where(documentId(), "in", chunk)))
        .then((snap) =>
          snap.forEach((doc) =>
            associatedHands.push({ serialNumber: doc.id, ...doc.data() } as FireStoreHand)
          )
        )
        .catch((e) => console.log(e))
    await Promise.all(chunkArray.map((chunk) => getHandsByChunk(chunk)))
    Promise.all(associatedHands.map((hand) => getLatestConfig(hand)))
      .then(resolve)
      .catch((e) => reject(e))
  })
}

export const getHandConfigs: (forHand: string, profile: ProfileData) => Promise<Config[]> = (
  forHand,
  profile
) => {
  return new Promise(async (resolve, reject) => {
    let acceptableUids: string[] = [profile.uid, "Unknown"]
    const isCovvi = [
      "Admin",
      "Tech Team Member",
      "Sales Team Member",
      "Customer Service Team Member",
    ].includes(profile.role)
    const ordered = (configs: Config[]) =>
      configs.sort((a, b) => {
        if (isNaN(Date.parse(a.date.toString())) && isNaN(Date.parse(b.date.toString()))) {
          return 0
        } else if (isNaN(Date.parse(b.date.toString()))) {
          return -1
        } else if (isNaN(Date.parse(a.date.toString()))) {
          return 1
        } else
          return Date.parse(b.date.toString()).valueOf() - Date.parse(a.date.toString()).valueOf()
      })
    if (!isCovvi) {
      let covviUids: string[] = getCovviUids()
      if (!covviUids.length) {
        covviUids = await setCovviUids()
      }
      acceptableUids = acceptableUids.concat(covviUids)
      profile.clinician && acceptableUids.push(profile.clinician)
      if (profile.associated_users) {
        acceptableUids = acceptableUids.concat(profile.associated_users)
      }
    }
    const configLibrary = await getDocs(collection(firestore, `Hands/${forHand}/Configs`)).then(
      (querySnapshot) => {
        const configs: Config[] = []
        querySnapshot.forEach((config) => {
          const validConfig = config.data()
          const configEdited = {
            ...validConfig,
            date: new Date(validConfig.date.seconds * 1000),
          } as Config
          if (validConfig) {
            if (validConfig.name === "Live Config") {
              configs.push({ ...configEdited, isLive: true })
            } else {
              configs.push(configEdited)
            }
          }
        })
        return isCovvi
          ? ordered(configs)
          : ordered(configs.filter((config) => acceptableUids.includes(config.setBy)))
      }
    )
    resolve(configLibrary)
  })
}

export const getHandData: (serialNumber: string) => Promise<HandFirestoreData | undefined> = (
  serialNumber
) => {
  return new Promise((resolve, reject) => {
    getDoc(doc(firestore, "Hands", serialNumber))
      .then((data) => {
        resolve(data.data())
      })
      .catch((e) => {
        reject(`${e}, - Failed to fetch hand data`)
      })
  })
}

export const getFittingDate: (serialNumber: string) => Promise<string> = (serialNumber) => {
  return new Promise((resolve, reject) => {
    getHandData(serialNumber)
      .then((handData) => {
        if (
          handData?.connection_events === undefined ||
          handData?.connection_events[handData.connection_events.length - 1].type !==
            "First connection since service"
        ) {
          resolve(ls.getText("Unknown Date"))
        } else {
          let date = new Date(
            handData?.connection_events[handData.connection_events.length - 1].date.seconds * 1000
          ).toISOString()

          resolve(date)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const uploadConfigHistory = (
  {
    configHex,
    configVersion,
    firmwareVersion,
    serialNumber,
    name,
    setBy,
    userGrips,
    archivedBy,
    userAppVersion,
    configImportedFrom,
  }: Config,
  profile: ProfileData
) => {
  return new Promise<true>(async (resolve, reject) => {
    const data = {
      name,
      configHex,
      firmwareVersion,
      configVersion,
      serialNumber,
      setBy,
      userGrips,
      archivedBy,
      date: new Date(),
      userAppVersion,
      configImportedFrom,
    }
    const upload = async (configToUpload: Config) => {
      let filteredData: Partial<Config> = {}
      Object.entries(configToUpload).forEach(([k, v]) => {
        if (v !== undefined) {
          filteredData[k as keyof Config] = v
        }
      })
      setDoc(doc(firestore, `Hands/${serialNumber}/Configs`, configToUpload.name), filteredData, {
        merge: true,
      })
    }
    const overwritableUids: string[] | undefined = profile.associated_users
      ? [profile.uid, ...profile.associated_users]
      : undefined
    const existingEntry = (
      await getDoc(doc(firestore, `Hands/${serialNumber}/Configs/${name}`))
    ).data()
    if (existingEntry === undefined) {
      upload(data)
        .then(() => resolve(true))
        .catch(() => reject("error"))
    } else if (
      overwritableUids?.includes(existingEntry.setBy) ||
      ["Admin", "Tech Team Member"].includes(profile.role)
    ) {
      upload({
        ...(existingEntry as Config),
        name: `ARCHIVED_${Date.now().toString()}_${existingEntry.name}`,
        archivedBy: profile.uid,
      })
        .then(() => upload(data))
        .then(() => resolve(true))
        .catch(() => reject("error"))
    } else {
      reject("error")
    }
  })
}

export const deleteConfig = (config: Config, serialNumber: string, profile: ProfileData) => {
  return new Promise((resolve, reject) => {
    if (config.setBy !== profile.uid && !["Admin", "Tech Team Member"].includes(profile.role)) {
      reject("Insufficient Permission")
      return
    }
    if (config.name && !config.name.startsWith("ARCHIVED")) {
      const configToArchive = {
        configHex: config.configHex,
        configVersion: config.configVersion?.toString() || ls.getText("Unknown"),
        firmwareVersion: config.firmwareVersion || ls.getText("Unknown"),
        serialNumber: serialNumber,
        name: `ARCHIVED_${Date.now().toString()}_${config.name}`,
        setBy: config.setBy,
        userGrips: config.userGrips && config.userGrips,
        archivedBy: profile.uid,
        date: config.date || new Date(),
        userAppVersion: config.userAppVersion || ls.getText("Unknown"),
      }
      uploadConfigHistory(configToArchive, profile)
        .then((res) => {
          resolve(res)
          // const fileToDeleteRef = ref(storage, `ConfigHistory/${serialNumber}/${config.name}.json`)
          deleteDoc(doc(firestore, `Hands/${serialNumber}/Configs/${config.name}`))
            // deleteObject(fileToDeleteRef)
            .then(() => resolve("Config Deleted"))
            .catch((e) => reject("Error Deleting Config"))
        })
        .catch((e) => reject("Error Archiving Config"))
    } else {
      deleteDoc(doc(firestore, `Hands/${serialNumber}/Configs/${config.name}`))
        .then(() => resolve("Config Deleted"))
        .catch((e) => reject("Error Deleting Config"))
      // deleteObject(ref(storage, `ConfigHistory/${serialNumber}/${config.name}.json`))
      //   .then(() => resolve("Config Deleted"))
      //   .catch((e) => reject("Error Deleting Config"))
    }
  })
}

export const getStats = ({
  handId,
  role,
  uid,
  associatedUsers,
}: {
  handId: string
  role: string
  uid: string
  associatedUsers?: string[]
}) => {
  return new Promise<SingleHandStats>((resolve, reject) => {
    const convertThumb = (snap: { [key: string]: number }) => {
      let returnSnap: { [key: string]: number } = {}
      const oldNames = [
        "thumb_tap_pronate_non_opposed",
        "thumb_tap_pronate_opposed",
        "thumb_tap_supinate_non_opposed",
        "thumb_tap_supinate_opposed",
      ]

      const newNames = [
        "non_opposed_thumb_tap_in",
        "opposed_thumb_tap_in",
        "non_opposed_thumb_tap_out",
        "opposed_thumb_tap_out",
      ]

      Object.entries(snap).forEach(([key, value]) => {
        if (oldNames.indexOf(key) >= 0) {
          returnSnap[newNames[oldNames.indexOf(key)]] = value as number
        } else {
          returnSnap[key] = value as number
        }
      })

      return returnSnap
    }
    const getAll = ["Admin", "Tech Team Member", "Customer Service Team Member"].includes(role)
    const docRef = getAll
      ? collection(firestore, `Hands/${handId}/AppStatistics`)
      : query(
          collection(firestore, `Hands/${handId}/AppStatistics`),
          where("set_by", "in", associatedUsers ? [...associatedUsers.slice(0, 28), uid] : [uid])
        )
    getDocs(docRef)
      .then((querySnapshot) => {
        let handStats = new Map<string, StatisticsWithConfigInfo>()
        let snapShotStats = querySnapshot.docs
        const first = getAll ? false : snapShotStats.shift()
        snapShotStats.forEach((statSnapshot) => {
          let snapshotEdited = statSnapshot.data()
          snapshotEdited = {
            ...snapshotEdited,
            data_triggers: convertThumb(snapshotEdited["data_triggers"]),
          }

          if (snapshotEdited["data_triggers"].hasOwnProperty(""))
            if (snapshotEdited["data_grips"] === undefined) {
              snapshotEdited["data_grips"] = {
                tripod: 0,
                power: 0,
                trigger: 0,
                precision_open: 0,
                precision_closed: 0,
                key: 0,
                finger_point: 0,
                mouse: 0,
                column: 0,
                relaxed: 0,
                glove: 0,
                finger_tap: 0,
                phone: 0,
                rock: 0,
                user_grip_1: 0,
                user_grip_2: 0,
                user_grip_3: 0,
                user_grip_4: 0,
                user_grip_5: 0,
                user_grip_6: 0,
              }
            }

          Object.entries({
            ...getStatisticsSchema(105),
            ...getStatisticsSchema(106),
            ...getStatisticsSchema(107),
          }).forEach((section) => {
            section[0] !== "data_limits" &&
              section[1].options.forEach((stat) => {
                if (
                  "title" in stat &&
                  statSnapshot.data()[section[0]] &&
                  !["thumb_in", "thumb_out", "thumb_further_in", "thumb_further_out"].includes(
                    stat.title
                  )
                ) {
                  const thisVal = parseInt(statSnapshot.data()[section[0]][stat.title])
                  if (!first) {
                    snapshotEdited[section[0]][stat.title] = thisVal | 0
                  } else {
                    const initialVal = parseInt(first!.data()[section[0]][stat.title])
                    if (thisVal > initialVal) {
                      snapshotEdited[section[0]][stat.title] = thisVal - initialVal
                    } else if (thisVal <= initialVal) {
                      snapshotEdited[section[0]][stat.title] = thisVal
                    } else {
                      snapshotEdited[section[0]][stat.title] = 0
                    }
                  }
                }
              })
          })
          handStats.set(statSnapshot.id, snapshotEdited as StatisticsWithConfigInfo)
        })
        resolve({ [handId]: handStats })
      })
      .catch((e) => {
        console.error("Failed to get stats", e)
        reject()
      })
  })
}

export const getLatestErrors = ({
  handId,
  uid,
  role,
  associatedUsers,
}: {
  handId: string
  uid: string
  role: string
  associatedUsers?: string[]
}) => {
  return new Promise<ErrorCodeData[]>((resolve, reject) => {
    const getAll = ["Admin", "Tech Team Member", "Customer Service Team Member"].includes(role)
    const docRef = getAll
      ? collection(firestore, `Hands/${handId}/HandErrors`)
      : query(
          collection(firestore, `Hands/${handId}/HandErrors`),
          where("set_by", "in", associatedUsers ? [...associatedUsers.slice(0, 28), uid] : [uid])
        )
    getDocs(docRef)
      .then((querySnapshot) => {
        let errorMap = new Map()
        if (getAll) {
          querySnapshot.forEach((errorSnap) => {
            let snapshotErrors = Object.values(errorSnap.data()) as ErrorCodeData[]
            snapshotErrors.pop()
            snapshotErrors.forEach((error) =>
              errorMap.set(`AT${error.absTime}T${error.type}C${error.code}`, error)
            )
          })
          resolve(Array.from(errorMap.values()))
        } else if (querySnapshot.docs.length) {
          let snapshotError = Object.values(
            querySnapshot.docs[querySnapshot.docs.length - 1].data() as ErrorCodeData[]
          )
          snapshotError.pop()
          resolve(snapshotError)
        }
      })
      .catch((e) => {
        console.error("failed to get errors", e)
        reject(e)
      })
  })
}

const updateHandField = (hand: string, attributeCollection: string, attribute: string) => {
  return new Promise((resolve) => {
    getDocs(collection(firestore, `Hands/${hand}/${attributeCollection}`))
      .then((querySnapshot) => {
        if (querySnapshot.size) {
          const timeStamps = Array.from(querySnapshot.docs)
            .map((doc) => doc.id)
            .sort((a, b) => parseInt(b) - parseInt(a))
          setDoc(
            doc(firestore, "Hands", hand),
            {
              [attribute]: timeStamps[0],
            },
            { merge: true }
          )
          resolve(true)
        } else resolve(false)
      })
      .catch((e) => resolve(false))
  })
}

export const getGeneralStats = (profile: ProfileData) => {
  return new Promise<ShortSerialStats>(async (resolve, reject) => {
    const startTime = Date.now()
    let handsToIgnore = [
      // default hand & Dave's default
      "0000",
      "1234",
      // cycle tested hands
      "0024",
      "1158",
      "1128",
      "1343",
      "1320",
    ]
    let allHandsObj: SingleHandStats = {}
    let shortSerialStats: ShortSerialStats

    let latest: StorageReference | undefined
    let hands: string[] = []

    const replacer = (key: string, value: any) => {
      if (value instanceof Map) {
        return {
          dataType: "Map",
          value: Array.from(value.entries()), // or with spread: value: [...value]
        }
      } else {
        return value
      }
    }

    const reviver = (key: string, value: any) => {
      if (typeof value === "object" && value !== null) {
        if (value.dataType === "Map") {
          return new Map(value.value)
        }
      }
      return value
    }

    const mergeHandsBySerial = (statsMapObj: SingleHandStats) => {
      const handsObj: SingleHandStats = {}
      Object.entries(statsMapObj).forEach(([serial, entry], i) => {
        for (const [date, statsObj] of entry) {
          const shortSerial = serial.substring(serial.length - 4, serial.length)
          if (!handsObj[shortSerial]) {
            handsObj[shortSerial] = new Map()
          }
          handsObj[shortSerial].set(date, statsObj)
        }
      })
      return handsObj
    }

    const consolidatedConfigs = (statsMapObj: SingleHandStats) => {
      const returnObj: ShortSerialStats = {}
      Object.entries(statsMapObj).forEach(([serial, entry], i) => {
        const shortSerial = serial.substring(serial.length - 4, serial.length)
        if (!returnObj[shortSerial]) {
          returnObj[shortSerial] = new Map()
        }
        let statsArray = Array.from(entry)
        statsArray.forEach(([date, statsObj], i) => {
          if (i === 0) {
            returnObj[shortSerial].set(date, statsObj)
          } else {
            let currentObj: StatsPoss = {
              data_counter: {},
              data_triggers: {},
              data_limits: {},
              data_usage: {},
              data_grips: {},
            }
            Object.entries(statsObj).forEach(([key, value]) => {
              const keyNamed = key as keyof StatisticsWithConfigInfo
              const prev = statsArray[i - 1][1]
              const cur = statsArray[i][1]
              if (keyNamed === "config_hex" && prev.config_hex !== cur.config_hex) {
                currentObj.config_hex = cur.config_hex
              }
              if (["config_hex", "set_by", "user_grips"].includes(keyNamed)) {
                return
              }
              if (keyNamed === "config_hex" && prev.config_hex !== cur.config_hex) {
                currentObj.config_hex = cur.config_hex
              } else {
                Object.entries(value).forEach(([statsParam, numVal]) => {
                  const statVal = numVal as number
                  switch (keyNamed) {
                    case "data_usage":
                      const dUsage = statsParam as keyof DataUsage
                      if (cur.data_usage[dUsage] !== prev.data_usage[dUsage])
                        currentObj.data_usage[dUsage] = statVal
                      break
                    case "data_grips":
                      const dGrips = statsParam as keyof DataGrips
                      if (cur.data_grips[dGrips] !== prev.data_grips[dGrips])
                        currentObj.data_grips[dGrips] = statVal
                      break
                    case "data_counter":
                      const dCounter = statsParam as keyof DataCounter
                      if (cur.data_counter[dCounter] !== prev.data_counter[dCounter])
                        currentObj.data_counter[dCounter] = statVal
                      break
                    case "data_triggers":
                      const dTriggers = statsParam as keyof DataTriggers
                      if (cur.data_triggers[dTriggers] !== prev.data_triggers[dTriggers])
                        currentObj.data_triggers[dTriggers] = statVal
                      break
                    case "data_limits":
                      const dLimits = statsParam as keyof DataLimits
                      if (cur.data_limits[dLimits] !== prev.data_limits[dLimits])
                        currentObj.data_limits[dLimits] = statVal
                      break
                    default:
                      console.warn("Error:", keyNamed, key, serial)
                      break
                  }
                })
              }
            })
            returnObj[shortSerial].set(date, currentObj)
          }
        })
      })
      return returnObj
    }

    const getInt = (ref: StorageReference) => parseInt(ref.name.split("_")[0])

    await listAll(ref(storage, "CachedStatsSnapshots/")).then((result) =>
      result.items.forEach((ref) => {
        if (!latest || getInt(ref) > getInt(latest)) {
          latest = ref
        }
      })
    )

    if (!latest || (latest && getInt(latest) + 604800000 < Date.now())) {
      // comment this ^^ to bypass stats caching
      await getDocs(query(collection(firestore, `Hands`))).then(
        async (res) =>
          await Promise.all(
            res.docs
              .map((hand) => hand.id)
              .map((hand) => updateHandField(hand, "AppStatistics", "latest_stats"))
          )
      )
      await getDocs(query(collection(firestore, `Hands`), where("latest_stats", "!=", null)))
        .then((querySnapshot) => querySnapshot.forEach((doc) => hands.push(doc.id)))
        .catch((e) => reject(`Couldn't get Stats: ${e}`))

      hands = hands.filter(
        (handId) =>
          !(handId.startsWith("CV1") && handsToIgnore.some((hand) => handId.endsWith(hand)))
      )

      await Promise.all(
        hands.map((hand) =>
          getStats({
            handId: hand,
            role: profile.role,
            uid: profile.uid,
            associatedUsers: profile.associated_users,
          })
        )
      )
        .then((res) =>
          res
            .filter((handStatsObj) => Object.values(handStatsObj)[0].size > 0)
            .forEach((hand) => {
              allHandsObj[Object.keys(hand)[0]] = Object.values(hand)[0]
            })
        )
        .catch((e) => reject(`Couldn't get Stats: ${e}`))

      allHandsObj = mergeHandsBySerial(allHandsObj)
      shortSerialStats = consolidatedConfigs(allHandsObj)

      if (shortSerialStats) {
        resolve(shortSerialStats)
        uploadBytes(
          ref(storage, `CachedStatsSnapshots/${Date.now()}.json`),
          new Blob(
            [
              JSON.stringify(
                {
                  set_by: profile.uid,
                  shortSerialStats: shortSerialStats,
                  date: new Date().toISOString().replace("T", " ").split(".")[0],
                },
                replacer
              ),
            ],
            { type: "application/json" }
          )
        ).then(() => console.log("TIME TAKEN TO COMPLETE:", Date.now() - startTime))
      } else {
        reject(`Couldn't retrieve stats for hands: ${hands}`)
      }
    } else {
      // comment this else block ^^ to bypass stats caching
      await getBlob(latest)
        .then(async (blob) => await blob.text())
        .then(async (json) => await JSON.parse(json, reviver))
        .then((statsSnaphot) => resolve(statsSnaphot.shortSerialStats))
        .catch(() => reject("Error retrieving latest cached stats"))
    }
  })
}

export const getGeneralErrors = (profile: ProfileData) => {
  return new Promise<GeneralErrorsObj[]>(async (resolve, reject) => {
    let allHandsArray: GeneralErrorsObj[] | void = []
    let latest: StorageReference | undefined
    let hands: string[] = []

    const getInt = (ref: StorageReference) => parseInt(ref.name.replace(".json", ""))

    const getHandErrors = (handId: string) => {
      return new Promise<GeneralErrorsObj>((resolve) => {
        const blankResolve = {
          handId,
          handErrors: [],
          entries: 0,
        }

        getDocs(collection(firestore, `Hands/${handId}/HandErrors`))
          .then((handSnapshot) => {
            const handErrors: ErrorAndTime[] = []
            handSnapshot.docs.forEach((snapshot, i) => {
              const errors = Object.values(snapshot.data()) as ErrorAndTime[]
              errors.pop()
              errors.forEach((er) => {
                const existsIndex = handErrors.findIndex(
                  (obj) =>
                    obj.absTime === er.absTime && obj.type === er.type && obj.code === er.code
                )
                if (existsIndex === -1) {
                  handErrors.push({ ...er, earliestDate: parseInt(snapshot.id) })
                } else if (parseInt(snapshot.id) < handErrors[existsIndex].earliestDate) {
                  handErrors[existsIndex].earliestDate = parseInt(snapshot.id)
                }
              })
            })
            handErrors.sort((a, b) => a.earliestDate - b.earliestDate)
            resolve({
              handId,
              handErrors,
              entries: handErrors.length,
            })
          })
          .catch(() => resolve(blankResolve))
      })
    }

    await listAll(ref(storage, "CachedErrorsSnapshots/")).then((result) =>
      result.items.forEach((ref) => {
        if (!latest || getInt(ref) > getInt(latest)) {
          latest = ref
        }
      })
    )

    if (!latest || (latest && getInt(latest) + 604800000 < Date.now())) {
      await getDocs(query(collection(firestore, "Hands"))).then(
        async (res) =>
          await Promise.all(
            res.docs
              .map((hand) => hand.id)
              .map((hand) => updateHandField(hand, "HandErrors", "latest_errors"))
          )
      )
      await getDocs(query(collection(firestore, `Hands`), where("latest_errors", "!=", null)))
        .then((querySnapshot) => querySnapshot.forEach((doc) => hands.push(doc.id)))
        .catch((e) => reject(`Couldn't get Errors: ${e}`))
      allHandsArray = await Promise.all(hands.map((hand) => getHandErrors(hand)))
        .then((res) => res.filter((hand) => hand.entries > 0))
        .catch((e) => reject(`Couldn't get Stats: ${e}`))
      if (allHandsArray) {
        resolve(allHandsArray)
        uploadBytes(
          ref(storage, `CachedErrorsSnapshots/${Date.now()}.json`),
          new Blob(
            [
              JSON.stringify({
                set_by: profile.uid,
                hands_array: allHandsArray,
                date: new Date().toISOString().replace("T", " ").split(".")[0],
              }),
            ],
            { type: "application/json" }
          )
        )
      } else {
        reject(`Couldn't retrieve Errors for hands: ${hands}`)
      }
    } else {
      await getBlob(latest)
        .then(async (blob) => await blob.text())
        .then(async (json) => await JSON.parse(json))
        .then((errorSnap) => resolve(errorSnap.hands_array))
        .catch(() => reject("Error retrieving latest cached stats"))
    }
  })
}

export const addAssociatedHand = (profileUid: string, serialNumberToAdd: string) => {
  return new Promise((resolve, reject) => {
    updateDoc(doc(firestore, "Users", profileUid), {
      associated_hands: arrayUnion(serialNumberToAdd),
    })
      .then(resolve)
      .catch(reject)
  })
}

export const sendConfigVersion100ProbableErrorEmail = (
  profile: ProfileData,
  serialNumber: string,
  configHex: string,
  remoteAssistRoom?: string
) => {
  return new Promise<string>(async (resolve, reject) => {
    const config100email = httpsCallable(functions, "configVersion100ProbableError")
    let emailData = {
      role: profile.role,
      name: `${profile.first_name} ${profile.last_name}`,
      emailAddress: profile.email_address,
      userUid: profile.uid,
      serialNumber: serialNumber,
      remoteAssistRoom: remoteAssistRoom,
      date: new Date().toString(),
      configHex: configHex,
    }

    config100email(emailData)
      .then(() => {
        resolve("Config version 100 probable error email sent.")
      })
      .catch((e) => {
        reject(`${e} - Failed to send config version 100 email`)
      })
  })
}

export const getLastSixFromSerial = (serial: string) => {
  return new Promise<string>(async (resolve, reject) => {
    const lastSixDigits = serial.slice(-6)
    let lastFourDigits = lastSixDigits.slice(2)
    let currentYear = new Date().getFullYear().toString().slice(2)
    for (let i = 19; i <= parseInt(currentYear); i++) {
      try {
        await getDoc(doc(firestore, "Hands", `${i}${lastFourDigits}`)).then(
          (doc) => doc.exists() && resolve(`${i}${lastFourDigits}`)
        )
      } catch {
        console.warn("Error looking up firebase collection")
      }
    }
    return resolve(lastSixDigits)
  })
}
