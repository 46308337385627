import { useEffect, useMemo, useState } from "react"

import { languageSelector as ls } from "@covvi/language-selector"
import { getConfigRows } from "@covvi/common-functions"
import { Parser, Config as ParserConfig } from "@util/parser/Parser"
import { Row, Config, ButtonObj } from "@typesFolder/types"
import { ConfigVersion } from "@covvi/common-functions/lib/types"
import { useAuth } from "@context/AuthContext"
import { getProfile } from "@util/firebase/authFunctions"

import TextInput from "@ui/inputs/ConfigInputs/TextInput"
import SelectInput from "@ui/inputs/ConfigInputs/SelectInput"
import NumberInput from "@ui/inputs/ConfigInputs/NumberInput"
import GripTables from "./handConfigSection/GripTables"
import GripSwitchTriggers from "./handConfigSection/GripSwitchTriggers"
import ConfigSystemInfoTab from "./ConfigSystemInfoTab"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import { ButtonRow } from "@ui/sections/ButtonRow"
import { SecondaryTabArray } from "@ui/sections/SecondaryTabArray"
import ConfigActions from "./handConfigSection/ConfigActions"

interface Props {
  data: Config | undefined
  setCurrentConfig?: Function | undefined
  canEdit?: boolean
  isLiveRemoteAssist?: boolean
  buttonArray?: ButtonObj[]
}

interface Section {
  title: string
  content: Row[]
}

const HandConfigSection = ({
  data,
  setCurrentConfig = undefined,
  canEdit = true,
  isLiveRemoteAssist = false,
  buttonArray,
}: Props) => {
  const { profile } = useAuth()
  const parser = useMemo(() => new Parser(), [])
  const [config, setConfig] = useState<ParserConfig>()
  const [configUI, setConfigUI] = useState<Section[] | undefined>()
  const [configVersion, setConfigVersion] = useState<ConfigVersion>()
  const [selected, setSelected] = useState("hand_control_title")
  const [setBy, setSetBy] = useState<string>()

  useEffect(() => {
    if (data && profile?.role === "Customer Service Team Member") {
      getProfile(data.setBy).then(() => {
        setSetBy(`${profile.first_name} ${profile.last_name}`)
      })
    } else {
      data && setSetBy(data.setBy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (data) {
      let tempConfigVersion = data.configVersion?.toString() || ls.getText("Unknown")
      if (tempConfigVersion.includes(".")) {
        tempConfigVersion = tempConfigVersion.split(".").join("")
      }
      const configTemp =
        data.parsed || parser.parse(data.configHex, undefined, parseInt(tempConfigVersion))
      setConfig(configTemp)
      setConfigVersion(parseInt(configTemp.params.config_version.value, 16) as ConfigVersion)
    }
  }, [data, parser])

  useEffect(() => {
    let tabs: Section[] = []
    let currentSection: Section
    if (config && configVersion) {
      getConfigRows(configVersion).forEach((row: Row, i: number) => {
        if (row.type === "header") {
          currentSection?.content?.length > 1 && tabs.push(currentSection)
          currentSection = { title: row.name, content: [] }
        } else if (row.type === "item" && config.params[row.name]) {
          currentSection.content.push(row)
        }
      })
    }

    let uI = [...tabs, currentSection!, { title: "System Information", content: [] }]
    isLiveRemoteAssist && uI.push({ title: "Config Actions", content: [] })
    ;[uI[2], uI[4]] = [uI[4], uI[2]]
    ;[uI[3], uI[4]] = [uI[4], uI[3]]

    config && setConfigUI([...uI])
  }, [config, configVersion])

  const updateParam = (paramName: string, value: string) => {
    if (paramName.split("_")[0] === "table") {
      if (value === "00") {
        config!.removeGrip(paramName)
      } else {
        config!.params[paramName].value === "00"
          ? config!.addGrip(paramName.split("_")[1], value)
          : config!.updateParamValue(paramName, value)
      }
    } else {
      config!.updateParamValue(paramName, value)
    }
    const parser = new Parser()
    setCurrentConfig && setCurrentConfig(parser.unparse(config))
    setRerender(!rerender)
  }
  const [rerender, setRerender] = useState(false)

  const content = () => {
    if (data && config && configUI !== undefined) {
      switch (selected) {
        case "grip_tables_title":
          return (
            <GripTables
              config={config}
              content={configUI.filter((tab) => tab.title === "grip_tables_title")[0]?.content}
              updateParam={updateParam}
              canEdit={canEdit}
              userGrips={data?.userGrips}
            />
          )
        case "System Information":
          return (
            <ConfigSystemInfoTab
              info={{
                configVersion: data.configVersion?.toString() || ls.getText("Unknown"),
                firmwareVersion: data.firmwareVersion || ls.getText("Unknown"),
                gatewareVersion: data.gatewareVersion || ls.getText("Unknown"),
                userAppVersion: data.userAppVersion || ls.getText("Unknown"),
                setBy: setBy || ls.getText("Unknown"),
                serialNumber: data.serialNumber || ls.getText("Unknown"),
                configImportedFrom: data.configImportedFrom || undefined,
              }}
            />
          )
        case "Config Actions":
          return <ConfigActions config={config} />
        case "grip_switch_triggers_title":
          return (
            <GripSwitchTriggers
              config={config}
              content={
                configUI.filter((tab) => tab.title === "grip_switch_triggers_title")[0]?.content
              }
              updateParam={updateParam}
              canEdit={canEdit}
              userGrips={data?.userGrips}
            />
          )

        default:
          return configUI
            .filter((tab) => tab.title === selected)[0]
            .content.map((row, i) => {
              switch (config.params[row.name].param_type) {
                case "OPTION":
                  let optionCanEdit = canEdit
                  if (
                    profile &&
                    profile.role !== "Admin" &&
                    row.name === "auto_grip" &&
                    configVersion &&
                    configVersion < 106 &&
                    config.params["auto_grip"].value === "00"
                  ) {
                    optionCanEdit = false
                  }
                  return (
                    <SelectInput
                      key={i}
                      value={config.params[row.name]}
                      setValue={updateParam}
                      canEdit={optionCanEdit}
                      helperText={
                        ls.getText(`${row.name}_message`) !== `${row.name}_message`
                          ? ls.getText(`${row.name}_message`)
                          : ""
                      }
                    />
                  )
                case "POSITIVE_INTEGER":
                case "INPUT_PERCENTAGE":
                  return (
                    <NumberInput
                      key={i}
                      value={config.params[row.name]}
                      setValue={updateParam}
                      canEdit={canEdit}
                      config={config}
                      helperText={
                        ls.getText(`${row.name}_message`) !== `${row.name}_message`
                          ? ls.getText(`${row.name}_message`)
                          : ""
                      }
                    />
                  )
                default:
                  return (
                    <TextInput key={i} value={config.params[row.name]} setValue={updateParam} />
                  )
              }
            })
      }
    } else
      return <div className="flex mx-auto text-lg">{ls.getText("No Data Found").toUpperCase()}</div>
  }

  return config && configUI !== undefined ? (
    <div className="flex flex-col sm:items-start">
      <SecondaryTabArray
        buttonArray={configUI}
        selected={selected}
        setSelected={(title) => setSelected(title)}
        isWithinCard={false}
      />
      <div className="w-full mt-4">
        <CVCardBody
          roundedTop={true}
          roundedBottom={
            !canEdit || selected === "Config Actions" || selected === "System Information"
          }
        >
          <div
            className={`w-full mt-2 grid md:grid-cols-1 
            ${
              ![
                "hand_control_title",
                "grip_tables_title",
                "System Information",
                "Config Actions",
                "grip_switch_triggers_title",
              ].includes(selected)
                ? " lg:grid-cols-2 "
                : ""
            } 
            ${selected === "input_settings_title" ? " lg:grid-rows-5 lg:grid-flow-col" : ""} 
            gap-x-10 p-2 md:p-10`}
          >
            {content()}
          </div>
        </CVCardBody>
        {buttonArray &&
          canEdit &&
          selected !== "Config Actions" &&
          selected !== "System Information" && (
            <CVCardFooter>
              <ButtonRow buttonArray={buttonArray} />
            </CVCardFooter>
          )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default HandConfigSection
