import { languageSelector as ls } from "@covvi/language-selector"

export const dataToDisplay = (column: string, item: any) => {
  switch (column) {
    case "latestErrors":
    case "latestInvalid_config":
    case "latestStats":
    case "latestCustomerConnection":
    case "latestCovviConnection":
    case "latestConfig":
      const dateAsInt = typeof item === "number" ? item : parseInt(item)
      if (dateAsInt === 0) {
        return ls.getText("Unknown Date")
      } else {
        return new Date(dateAsInt).toLocaleDateString("en-gb", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      }
    case "date":
      if (item === 0) {
        return ls.getText("Unknown Date")
      } else if (item === ls.getText("Unknown Date")) {
        return item
      } else {
        return new Date(item).toLocaleDateString("en-gb", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
      }
    case "full_name":
      return item
        .split(" ")
        .map((personName: string) => personName.slice(0, 1).toUpperCase() + personName.slice(1))
        .join(" ")
    case "ticketStatus":
      return item.slice(0, 1).toUpperCase() + item.slice(1)
    case "serialNumber":
      return item && item.toUpperCase()
    default:
      return item as string
  }
}
