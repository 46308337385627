export function replaceAt(fullString: string, index: number, replacement: string) {
  return (
    fullString.substring(0, dStr(index)) +
    replacement +
    fullString.substring(dStr(index) + replacement.length)
  )
}

export function dStr(val: number) {
  return val * 2
}
